<!-- 地图坐标点列表 -->
<template>
  <div class="mapBox">
    <div class="header">
      <img class="header-logo-ci" src="@/assets/image/zhfe/zhfCI.png" />
      <img class="header-logo" src="@/assets/image/zhfe/zhfelogo.png" />
      <span class="header-txt">智惠妇儿</span>
      <!-- 关闭 刷新 -->
      <div class="idx-middlesx-box3">
        <div class="idx-middle-1">
          <img
            class="fh"
            src="@/assets/image/zhfe/return.png"
            @click="goBack"
          />
          <img
            class="sx"
            src="@/assets/image/zhfe/icon/sx.png"
            alt=""
            @click="refresh"
          />
        </div>
      </div>
    </div>
    <!-- 时间 -->
    <div class="time-box">
      <Date></Date>
    </div>
    <div class="page_title">议事方式管理</div>
    <div class="content">
      <div class="map-seach">
        <div class="seach-left">
          <div class="seach-page">
            <el-input
              v-model="ysfsQuery.dataDetailed"
              placeholder="搜索"
            ></el-input>
          </div>
          <div class="btn_box">
            <div class="query_btn" @click="_getWomanMeetList">查询</div>
            <div class="query_btn" @click="onReset">重置</div>
          </div>
        </div>
        <div class="seach-right">
          <div class="query_btn" @click="addMap">新增</div>
        </div>
      </div>
      <div class="map-table">
        <el-table
          :data="tableData"
          height="100%"
          row-class-name="rowName"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="序号"
            width="160"
          ></el-table-column>
          <el-table-column
            prop="sort"
            label="排序"
            width="160"
          ></el-table-column>
          <el-table-column
            prop="dataDetailed"
            label="议事方式"
          ></el-table-column>
          <el-table-column label="操作" width="130">
            <template slot-scope="scope">
              <el-button @click="_updateMap(scope.row)" type="text" size="small"
                ><i class="el-icon-edit"></i> 修改</el-button
              >
              <el-button @click="delMap(scope.row)" type="text" size="small"
                ><i class="el-icon-delete"></i> 删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination_box">
        <el-pagination background layout="total" :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 图片查看 -->
    <el-dialog
      :visible.sync="imgshow"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="51vw"
      center
    >
      <div class="dialogTitleBox">
        <div class="dialogTitle">图片查看</div>
        <div class="dialogClose" @click="imgshow = false"></div>
      </div>
      <div class="dialogMainImg">
        <div v-for="(item, i) in imgList" :key="i" class="imgbox">
          <img :src="item" />
        </div>
      </div>
    </el-dialog>
    <!-- 新增 -->
    <el-dialog
      :visible.sync="addMapshow"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="50vw"
      center
    >
      <div class="dialogTitleBox">
        <div class="dialogTitle">{{ mapTitle }}</div>
        <div class="dialogClose" @click="closeDialog"></div>
      </div>
      <div class="mapdialogBox">
        <div class="map-item">
          <div class="map-label"><span>*</span>议事方式</div>
          <div class="map-input">
            <el-input
              class="seach-page"
              type="text"
              v-model="updateMapData.dataDetailed"
            />
          </div>
        </div>
        <div class="map-item">
          <div class="map-label"><span>*</span>排序</div>
          <div class="map-input">
            <el-input
              class="seach-page"
              type="text"
              v-model.number="updateMapData.sort"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            />
          </div>
        </div>
        <div class="dialog_btn" style="display: block">
          <div style="margin-right: 50px" @click="closeDialog">取消</div>
          <div @click="onSubmit">确定</div>
        </div>
      </div>
    </el-dialog>
    <!-- 删除 -->
    <dialogAlert
      :dialogVisible="dialogVisible"
      :dialogData="dialogData"
      @closeDialog="closeDialogDel"
      @determine="determine"
    >
      <span slot="txt">{{ delDataTxt }}</span>
    </dialogAlert>
  </div>
</template>

<script>
import Date from "@/views/commonComponents/date.vue";
import { listData } from "@/api/dtdata.js";
import { listMap, addMap, updateMap, delMap } from "@/api/map";
import { getWomanMeetList, getNumber, add, update, del } from "@/api/womanMeet";
import dialogAlert from "@/views/homeImg/components/dialogAlert.vue";
export default {
  components: {
    Date,
    dialogAlert,
  },
  data() {
    return {
      tableData: [],
      queryParam: {
        remark: "",
        type: "",
        pageSize: 10,
        pageNum: 1,
      },
      total: 0,
      typeList: [],
      maptypeList: [], //地图类别
      loading: false,
      imgshow: false, //图片显示
      imgList: [], //图片列表
      addMapshow: false, //新增修改坐标
      mapTitle: "",
      uploadAction: "/prod-api/common/upload", //图片上传地址
      // imageUrl: '',
      uploadImglist: [], // 上传的图片
      updateMapData: {
        dataName: "",
        dataDetailed: "",
        dataField1: "",
        dataField3: "",
        dataField4: "",
        dataField2: "",
        sort: 1,
      },
      isupdate: false,
      dialogVisible: false, // 删除的弹窗
      dialogData: "", // 删除的数据
      delDataTxt: "", //删除的提示

      yxalQuery: {
        // 优秀案例
        dataModule: "fnysh",
        dataTyp: "fnysh-yxal",
        status: 0,
        dataName: "",
      },
      ysfsQuery: {
        // 议事方式
        dataModule: "fnysh",
        dataTyp: "fnysh-ysfs",
        status: 0,
        dataDetailed: "",
      },
      yxalList: [],
      yxalIndex: 0,
      yxalimageQuery: {
        // 优秀案例-图片
        dataModule: "fnysh-yxal-image",
        dataTyp: "",
      },
    };
  },
  methods: {
    async _updateMap(row) {
      console.log("编辑数据", row);
      this.mapTitle = "修改议事方式";
      let islogin = this.islogin();
      if (islogin == 1) {
        this.updateMapData = {
          ...row,
        };
        this.addMapshow = true;
        this.isupdate = true;
      }
    },
    async _getWomanMeetList() {
      const { rows, total } = await getWomanMeetList(this.ysfsQuery);
      console.log("获取数据", rows);
      this.tableData = rows;
      this.total = total;
      this.loading.close();
    },
    // 关闭弹窗
    closeDialog() {
      this.addMapshow = false;
      this.updateMapData = {
        dataName: "",
        dataDetailed: "",
        dataField1: "",
        dataField3: "",
        dataField4: "",
        dataField2: "",
        sort: 1,
      };
      this.uploadImglist = [];
    },
    // 新增
    addMap() {
      let islogin = this.islogin();
      if (islogin == 1) {
        this.mapTitle = "新增议事方式";
        this.uploadImglist = [];
        this.addMapshow = true;
        this.isupdate = false;
      }
    },
    closeDialogDel() {
      this.dialogVisible = false;
    },

    determine(datas) {
      del(datas.dataId)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this._getWomanMeetList();
          }
        })
        .finally(() => {
          this.dialogVisible = false;
        });
    },
    // 删除
    delMap(data) {
      let islogin = this.islogin();
      if (islogin == 1) {
        this.dialogVisible = true;
        this.delDataTxt = "您确定要删除 " + data.dataDetailed + " 吗?";
        this.dialogData = data;
      }
    },
    // 查看图片
    async lookMap(data) {
      let islogin = this.islogin();
      if (islogin > 0) {
        let lodings = this.$loading({
          lock: true,
          text: "加载中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.imgList = [];
        console.log("獲取圖片", data);
        const { dataId } = data || {};
        this.yxalimageQuery.dataTyp = dataId;
        const { rows } = await getWomanMeetList(this.yxalimageQuery);
        console.log("返回图片数据", rows);
        this.imgshow = true;
        rows.map((item) => {
          this.imgList.push(
            item.dataDetailed
          );
        });
        lodings.close();
      }
    },
    //查询
    onQuery() {
      this.loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.tableData = [];
      listMap({
        mapName: this.queryParam.remark,
        mapTyp: this.queryParam.type,
        pageSize: this.queryParam.pageSize,
        pageNum: this.queryParam.pageNum,
      })
        .then((res) => {
          res.rows.forEach((item) => {
            this.maptypeList.forEach((typeitem) => {
              if (typeitem.dataDetailed == item.mapTyp) {
                item.typeName = typeitem.dataName;
              }
            });
            this.tableData.push(item);
          });
          this.total = res.total;
        })
        .finally(() => {
          this.loading.close();
        });
    },
    // 图片新增修改确认
    async onSubmit() {
      if (!this.updateMapData.dataDetailed.trim()) {
        this.$message.warning("请输入议事方式");
      } else {
        this.loading = this.$loading({
          lock: true,
          text: "加载中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        if (this.isupdate) {
          const data = {
            ...this.updateMapData,
            images: [],
          };
          console.log("更新", data);
          const res = await update(data);
          if (res.code == 200) {
            this.$message.success("修改成功");
            this.closeDialog();
            this._getWomanMeetList();
          }
          console.log("更新结果", res);
        } else {
          const data = {
            dataModule: "fnysh",
            dataTyp: "fnysh-ysfs",
            ...this.updateMapData,
            images: [],
          };
          const res = await add(data);
          if (res.code == 200) {
            this.$message.success("添加成功");
            this.closeDialog();
            this._getWomanMeetList();
          }
          console.log("新增结果", res);
        }
      }
    },
    // 重置
    onReset() {
      this.ysfsQuery.dataDetailed = "";
      this._getWomanMeetList();
    },
    refresh() {
      this.$router.go(0); //刷新当前页面
    },
    goBack() {
      this.$router.go(-1);
    },
    // 图片上传成功
    imgsuccess(res, file) {
      if (res.code == 200) {
        // this.imageUrl = URL.createObjectURL(file.raw);
        // this.uploadImglist.push(res.fileName)
        this.uploadImglist.push({
          url: res.fileName,
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
      console.log(this.uploadImglist);
      this.loading.close();
    },
    handleRemove(file, fileList) {
      this.uploadImglist = [];
      fileList.forEach((item) => {
        this.uploadImglist.push(item);
      });
    },
    // 上传失败
    imgerror() {
      this.$message({
        type: "error",
        message: "上传失败",
      });
      this.loading.close();
    },
    // 上传中
    beforeAvatarUpload(file) {
      this.loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 < 150;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式");
      } else if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 150kb");
      }
      this.loading.close();
      return isJPG && isLt2M;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`最多只能上传10张图片`);
    },
    // 判断是否登录
    islogin() {
      // 1为编辑，2为阅读，0为未登录
      const pass = this.$cookies.get("pass");
      let returndata = 0;
      if (pass == this.readPass || pass == this.editPass) {
        if (this.existSystem()) {
          returndata = 1;
        } else {
          returndata = 2;
        }
      } else {
        returndata = 0;
      }
      return returndata;
    },
  },
  mounted() {
    this.loading = this.$loading({
      lock: true,
      text: "加载中...",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    this._getWomanMeetList();
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
